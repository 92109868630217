<template>
    <div class="navContainer">
        <div class="logo bigFont" @click="$router.push('/')">
            <img src="./assets/logo2.jpeg" alt="" style=" height: 68px; "/>

        </div>
        <el-menu
                mode="horizontal"
                @select="handleSelect"
                :default-active="$route.path"
                style="height:90px;justify-content: flex-end;"
                router
                active-text-color="#3eac66"
        >
            <el-menu-item index="/">首页</el-menu-item>
            <el-sub-menu index="/financialecurity">
                <template #title>
                    <div @click="handle1">智慧金融</div>
                </template>
                <el-sub-menu index="/1">
                    <template #title>我是企业</template>
                    <el-menu-item index="/creditPage1">我想配对信贷产品</el-menu-item>
                    <el-menu-item index="/creditPage2">我想学习企业管理</el-menu-item>
                    <el-menu-item index="/creditPage3">我想做企业健康体检</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="/2">
                    <template #title>我是银行</template>
                    <el-menu-item index="/creditPage4">我想定制风险控制系统</el-menu-item>
                    <el-menu-item index="/creditPage5">我想定制服务质检系统</el-menu-item>
                    <el-menu-item index="/creditPage6">我想管理我的门店/客户</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="/3">
                    <template #title>我是融担/保理</template>
                    <el-menu-item index="/creditPage7">我想采购企业风险评估服务</el-menu-item>
                    <el-menu-item index="/creditPage8">我想定制风险控制系统</el-menu-item>
                    <el-menu-item index="/creditPage9">我想了解联合运营风控产品</el-menu-item>
                </el-sub-menu>
            </el-sub-menu>
            <el-sub-menu index="/healthcare">
                <template #title>
                    <div @click="handle2">智慧健康</div>
                </template>
                <el-sub-menu index="/4">
                    <template #title>我是孕妈</template>
                    <el-menu-item><a href="http://www.p2ifamily.com/maternity_p2i"> 孕前健康管理</a></el-menu-item>
                    <el-menu-item><a href="http://www.p2ifamily.com/">孕中健康管理</a></el-menu-item>
                    <el-menu-item><a href="http://www.p2ifamily.com/">孕后健康管理</a></el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="/5">
                    <template #title>我是宝妈</template>
                    <el-menu-item><a href="http://www.p2ifamily.com/">心理健康管理</a></el-menu-item>
                    <el-menu-item><a href="http://www.p2ifamily.com/">睡眠健康管理</a></el-menu-item>
                    <el-menu-item><a href="http://www.p2ifamily.com/brain_development">脑功能健康管理</a></el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="/6">
                    <template #title>我是长者</template>
                    <el-menu-item><a href="http://www.p2ifamily.com/brain_development">脑功能健康管理</a></el-menu-item>
                </el-sub-menu>
            </el-sub-menu>
            <el-sub-menu index="/hr">
                <template #title>
                    <div @click="handle2">智慧人力</div>
                </template>
                <el-sub-menu index="/7">
                    <template #title>我是社会组织</template>
                    <el-menu-item index="/hr/standardization">我想开发职业技能标准</el-menu-item>
                    <el-menu-item index="/learn/startTest">我想定制行业人才管理系统</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="/8">
                    <template #title>我是企业</template>
                    <el-menu-item index="/doctor">我想定制岗位胜任力测评</el-menu-item>
                    <el-menu-item index="/hr/skillEvaluate">我想定制企业人力管理系统</el-menu-item>
                </el-sub-menu>
                <el-sub-menu index="/9">
                    <template #title>我是追梦者</template>
                    <el-menu-item index="/learn/continuEducation">我想就业</el-menu-item>
                    <el-menu-item index="/learn/certificateManagement">我想创业</el-menu-item>
                    <el-menu-item index="/learn/learn">我想学习职业技能课程</el-menu-item>
                </el-sub-menu>
            </el-sub-menu>
            <!--      <el-sub-menu index="/hr">-->
            <!--        <template #title><div @click = "handle">智慧人力</div></template>-->
            <!--        <el-menu-item index="/hr/standardization">职业标准制定</el-menu-item>-->
            <!--        <el-menu-item index="/hr/skillEvaluate">职业技能等级评价/认定</el-menu-item>-->
            <!--        <el-menu-item index="/oneplusx">1+X证书</el-menu-item>-->
            <!--&lt;!&ndash;        <el-menu-item index="/doctor">岗位胜任力测评</el-menu-item>&ndash;&gt;-->
            <!--        <el-sub-menu index="/learn">-->
            <!--          <template #title>学考评台</template>-->
            <!--          <el-menu-item index="/learn/startTest">起点测试</el-menu-item>-->
            <!--          <el-menu-item index="/learn/learn">精准学习</el-menu-item>-->
            <!--          <el-menu-item index="/learn/exam">培训考试</el-menu-item>-->
            <!--          <el-menu-item index="/learn/evaluate">评价认定</el-menu-item>-->
            <!--          <el-menu-item index="/learn/certificateManagement">证书管理</el-menu-item>-->
            <!--          <el-menu-item index="/learn/continuEducation">继续教育</el-menu-item>-->
            <!--&lt;!&ndash;          <el-menu-item index="/learn/price">价格</el-menu-item>&ndash;&gt;-->
            <!--        </el-sub-menu>-->

            <!--      </el-sub-menu>-->

<!--            <el-menu-item index="/contactUs">联系裕康</el-menu-item>-->
            <el-menu-item index="/loginPage">登录 | 注册</el-menu-item>
        </el-menu>
    </div>
    <router-view/>
    <div class="footer">
        <div class="betweenFlex" style="">
            <div class="flex3">
                <p class="midFont blackFont">裕康云科技（深圳）有限公司</p>
                <p class="smallFont blackFont">YUKON SYSTEMS</p>

                <p class="smallFont blackFont">www.yukonsys.com.cn</p>
                <!--        <p class="smallFont greyFont">-->
                <!--          地址：广东省深圳市南山区侨乡路4068号智慧广场A栋5层-->
                <!--        </p>-->
                <!--        <p class="smallFont blackFont">工作时间：9:00 - 18:00（工作日）</p>-->
                <!--        <p class="smallFont greyFont">电话咨询：(0755) 8453 2874</p>-->
            </div>
            <div class="flex3">
                <!--        <p class="midFont whiteFont">电子邮箱</p>-->
                <p class="midFont blackFont">服务支持</p>
                <p class="smallFont blackFont textH" @click="goto('/contactUs')">商务合作：partner@yukonalliant.com</p>
                <p class="smallFont blackFont textH" @click="goto('/contactUs')">服务咨询：service@yukonalliant.com</p>
                <p class="smallFont blackFont textH" @click="toAsideText(1)">相关服务条款 / 法律条款</p>
            </div>
            <!--        <div class="flex2">-->
            <!--            &lt;!&ndash;        <p class="midFont whiteFont">电子邮箱</p>&ndash;&gt;-->
            <!--            <p class="midFont blackFont">法律条款</p>-->
            <!--            <p class="smallFont blackFont "><span class="textH" @click="toAsideText(1)">隐私条款</span></p>-->
            <!--            <p class="smallFont blackFont"><span class="textH" @click="toAsideText(2)">版权声明</span></p>-->
            <!--            <p class="smallFont blackFont"><span class="textH" @click="toAsideText(3)">免责声明</span></p>-->
            <!--            <p class="smallFont blackFont"><span class="textH" @click="toAsideText(4)">服务条款</span></p>-->
            <!--        </div>-->
            <div class="flex2" style="display: flex;flex-direction: row">
                <!--        <p class="midFont whiteFont">电子邮箱</p>-->
                <div>
                    <p class="midFont blackFont">相关课程</p>
                    <p class="smallFont blackFont textH" @mouseover="mouseover" @mouseleave="mouseleave">智慧金融</p>
                    <p class="smallFont blackFont textH" @mouseover="mouseover" @mouseleave="mouseleave">智慧健康</p>
                    <p class="smallFont blackFont textH" @mouseover="mouseover" @mouseleave="mouseleave">智慧人力</p>
                </div>
                <div>
                    <img v-show="yc" style="position:relative;width: 100px ;top:60px;left:0"
                         src="../src/assets/xcxcode.jpg">
                </div>
                <!-- 先隐藏 -->
            </div>


            <div class="flex2">
                <!--          <p class="midFont whiteFont">联系方式</p>-->
                <!--        <p class="smallFont greyLightFont">智慧金融:   13827418998</p>-->
                <!--        <p class="smallFont greyLightFont">智慧健康:   13828484169</p>-->
                <!--        <p class="smallFont greyLightFont">智慧人力:   13823380394</p>-->
                <!--        <p class="smallFont greyLightFont">service@yukonalliant.com</p>-->
                <p class="midFont blackFont">关注我们</p>
                <p class="smallFont greenFont" style="display: flex;flex-direction: column;">
                    <img src="@/assets/xcxcode.jpg" alt="" style="width: 100px"/>
                    <!--            <span style="margin-top: 10px">裕康小程序</span>-->
                </p>
            </div>
        </div>

    </div>
    <div class="footerBottom centerFont">
        Copyright ©www.yukonsys.com.cn<br/>
        <a href="https://beian.miit.gov.cn">备案号：粤ICP备19017406号-4</a>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeIndex: "1",
            yc: false,
        };
    },
    mounted() {
    },
    methods: {
        mouseover() {
            // 鼠标移入事件mouseover
            this.yc = true;
        },
        mouseleave() {
            // 鼠标移出事件mouseleave
            this.yc = false;
        },

        handleSelect(...e) {
            console.log(e);
        },
        goto(e) {
            //  路径/home对应我在router目录下index.js中定义的path属性值
            this.$router.push(e);
        },
        handle() {
            //  路径/home对应我在router目录下index.js中定义的path属性值
            this.$router.push('/professionalStandards');
        },
        handle1() {
            //  路径/home对应我在router目录下index.js中定义的path属性值
            this.$router.push('/financialecurity');
        },
        handle2() {
            //  路径/home对应我在router目录下index.js中定义的path属性值
            this.$router.push('/healthcare');
        },
        toAsideText(e) {
            //  路径/home对应我在router目录下index.js中定义的path属性值
            this.$router.push({
                path: "/asideText",
                query: {
                    e: e,
                }
            });
        }
    },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {

  margin: 0;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.navContainer {
  width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  z-index: 999;
  position: fixed;
  top: 0;

  .logo {
    position: absolute;
    left: 40px;
    z-index: 100;
    cursor: pointer;
    display: flex;
    top: 10px;
    align-items: center;
    justify-content: flex-start;
  }
}

.footer {
  //  background: rgba(34, 36, 41, 1);
  background: snow;
  padding: 30px calc((100vw - 1280px) / 2);
  text-align: left;
}

.footerBottom {
  color: #666;
  background: snow;
  padding: 10px;
}

.betweenFlex {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}

.aroundFlex {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.centerAlignFlex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bigFont {
  font-size: 32px;
}

.midFont {
  font-size: 24px;
}

.smallFont {
  font-size: 16px;
}

.miniFont {
  font-size: 12px;
}

.greyFont {
  color: #666;
}

.greyLightFont {
  color: #CCCCCC;
}

.blueFont {
  color: #409eff;
}

.centerText {
  text-align: center;
}

.leftText {
  text-align: left;
}

.rightText {
  text-align: right;
}

.whiteFont {
  color: #fff;
}

.blackFont {
  color: #666;
}

.boldFont {
  font-weight: bold;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.flex3 {
  flex: 3;
}

.flex4 {
  flex: 4;
}

.flex5 {
  flex: 5;
}

img {
  object-fit: contain;
  width: 100%;
}

.greenFont {
  color: #3eac66;
}

a:link {
  text-decoration: none;
  color: #666;
}



a:visited {
  text-decoration: none;
  color: #333333;
}

a:hover {
  color: #3eac66;
}

.el-sub-menu__title:hover {
  color: #3eac66 !important;
}

.el-menu-item:hover {
  color: #3eac66 !important;
}

::v-deep .el-menu-item:hover {
  color: #3eac66 !important; //修改鼠标移动到表头时候字体颜色，默认淡蓝色
}

.tx {
  cursor: pointer;
  width: 100%;
  padding: 10px;
  //background: #fff;
  transition: 0.3s all;
  border-bottom: 2px solid #fff;
  position: relative;

  &:before {
    //&和：之间不能有空格
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    top: 0;
    left: 0;
    transition: 0.3s all;
  }

  &:after {
    //&和：之间不能有空格
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    right: 0;
    bottom: 0px;
    transition: 0.3s all;
  }

  &:hover {
    border-bottom: 2px solid #fff;
    transform: translateY(-2px);

    &:after {
      width: 80%;
      height: 100%;
      border-bottom: 1px solid #3eac66;
      border-right: 1px solid #3eac66;
    }

    &:before {
      width: 80%;
      height: 100%;
      border-top: 1px solid #3eac66;
      border-left: 1px solid #3eac66;
    }

    // background-color: ;
    // .detail {
    //   color: #fff;
    // }
  }
}

.textH:hover {
  cursor: pointer;
  // color: #3eac66;
  color: #3eac66;
}

.col {
  color: #3eac66;
  font-weight: bolder;
  margin-right: 10px;
}
</style>
